var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-media',{staticClass:"mb-2",scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{ref:"previewEl",attrs:{"src":_vm.userData.avatar,"text":_vm.avatarText(_vm.userData.fullName),"variant":("light-" + (_vm.resolveUserRoleVariant(_vm.userData.role))),"size":"90px","rounded":""}})]},proxy:true}])},[_c('h4',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.userData.fullName)+" ")])]),_c('validation-observer',{ref:"userForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleUpdateUser.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"First Name","label-for":"userFirstName"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"firstName"},model:{value:(_vm.userData.firstName),callback:function ($$v) {_vm.$set(_vm.userData, "firstName", $$v)},expression:"userData.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Last Name","label-for":"lastName"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lastName"},model:{value:(_vm.userData.lastName),callback:function ($$v) {_vm.$set(_vm.userData, "lastName", $$v)},expression:"userData.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email","state":errors.length > 0 ? false:null},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Phone Number","label-for":"phoneNumber"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"phoneNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phoneNumber"},model:{value:(_vm.userData.phoneNumber),callback:function ($$v) {_vm.$set(_vm.userData, "phoneNumber", $$v)},expression:"userData.phoneNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Status","label-for":"user-status"}},[_c('v-select',{staticClass:"text-capitalize",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"user-status","disabled":!_vm.userData.id},model:{value:(_vm.userData.status),callback:function ($$v) {_vm.$set(_vm.userData, "status", $$v)},expression:"userData.status"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"User Role","label-for":"user-role"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.roleOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"user-role"},model:{value:(_vm.userData.role),callback:function ($$v) {_vm.$set(_vm.userData, "role", $$v)},expression:"userData.role"}})],1)],1)],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs',"disabled":invalid,"type":"submit"}},[_vm._v(" Save Changes ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }