<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.avatar"
          :text="avatarText(userData.fullName)"
          :variant="`light-${resolveUserRoleVariant(userData.role)}`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ userData.fullName }}
      </h4>
      <!-- <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline">Update</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
        >
          <span class="d-none d-sm-inline">Remove</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div> -->
    </b-media>

    <!-- User Info: Input Fields -->
    <validation-observer
      ref="userForm"
      #default="{invalid}"
    >
      <b-form @submit.prevent="handleUpdateUser">
        <b-row>

          <!-- Field: First Name -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="First Name"
              label-for="userFirstName"
            >
              <validation-provider
                #default="{ errors }"
                name="First Name"
                rules="required|min:3"
              >
                <b-form-input
                  id="firstName"
                  v-model="userData.firstName"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Last Name -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Last Name"
              label-for="lastName"
            >
              <validation-provider
                #default="{ errors }"
                name="Last Name"
                rules="required|min:3"
              >
                <b-form-input
                  id="lastName"
                  v-model="userData.lastName"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Email -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userData.email"
                  type="email"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Phone Number -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Phone Number"
              label-for="phoneNumber"
            >

              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="phoneNumber"
              >
                <b-form-input
                  id="phoneNumber"
                  v-model="userData.phoneNumber"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>
          </b-col>

          <!-- Field: Status -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Status"
              label-for="user-status"
            >
              <v-select
                v-model="userData.status"
                class="text-capitalize"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-status"
                :disabled="!userData.id"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Role -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="User Role"
              label-for="user-role"
            >
              <v-select
                v-model="userData.role"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-role"
              />
            </b-form-group>
          </b-col>

        <!-- Field: Role -->

        </b-row>
        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :disabled="invalid"
          type="submit"
        >
          Save Changes
        </b-button>
      </b-form>
    </validation-observer>

  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted, ref } from '@vue/composition-api'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import phoneNumber from '@/libs/utils/validations/customValidations'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import userStoreModule from '@/views/user/userStoreModule'
import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // const USER_APP_STORE_MODULE_NAME = 'app-user'

    // // Register module
    // if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // // UnRegister on leave
    //  onUnmounted(() => {
    //   if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    // })
    const { resolveUserRoleVariant } = useUsersList()

    const roleOptions = [
      { label: 'Admin', value: 'ROLE_ADMIN' },
      { label: 'Driver', value: 'ROLE_DRIVER' },
      { label: 'User', value: 'ROLE_USER' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'status.pending' },
      { label: 'Verified', value: 'status.verified' },
      { label: 'Blocked', value: 'status.blocked' },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    return {
      resolveUserRoleVariant,
      avatarText,
      roleOptions,
      statusOptions,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
  data() {
    return {
      // Validation Rules
      required,
      email,
      phoneNumber,
    }
  },
  methods: {
    handleUpdateUser() {
      const userId = this.userData.id
      const userData = {
        firstName: this.userData.firstName,
        lastName: this.userData.lastName,
        email: this.userData.email,
        phoneNumber: this.userData.phoneNumber,
        role: this.userData.role,
      }

      if (userId !== null) return this.updateUser(userId, userData)
      return this.addUser(userData)
    },
    addUser(userData) {
      store.dispatch('app-user/addUser', userData)
        .then(response => {
          this.$router.replace({ name: 'users-view', params: { id: response.data.id } })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'User updated',
                  icon: 'CheckSquareIcon',
                  variant: 'success',
                  text: `You have successfully updated ${this.userData.firstName}'s details`,
                },
              })
            })
        })
        .catch(error => {
          if (error.response) {
            if (error.response.status === 403 || error.response.status === 400) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'User Update Failed',
                  icon: 'XIcon',
                  variant: 'danger',
                  text: `${error.response.data.message}`,
                },
              })
            }
          }
        })
    },
    updateUser(id, userData) {
      this.$refs.userForm.validate().then(success => {
        if (success) {
          store.dispatch('app-user/updateUser', this.userData)
            .then(() => {
              this.$router.replace({ name: 'users-list' })
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'User updated',
                      icon: 'CheckSquareIcon',
                      variant: 'success',
                      text: `You have successfully updated ${this.userData.firstName}'s details`,
                    },
                  })
                })
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status === 403 || error.response.status === 400) {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'User Update Failed',
                      icon: 'XIcon',
                      variant: 'danger',
                      text: `${error.response.data.message}`,
                    },
                  })
                }
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
